
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















.home-activities-item {
  position: relative;
  overflow: hidden;

  ::v-deep {
    .picture {
      aspect-ratio: 3/2;
      width: 100%;
      height: auto;
      min-height: unset;
      max-height: unset;

      @include mq(l) {
        aspect-ratio: 2/3;
      }
    }

    img {
      transition: transform $transition-duration $ease-out;
    }
  }

  &:hover {
    ::v-deep {
      img {
        transform: scale(1.05) !important;
      }
    }

    .home-activities-item__link {
      &::after {
        transform: scale(1.1);
      }

      ::v-deep {
        .link__icon {
          transition-timing-function: $ease-out;

          &.active {
            opacity: 1;
            transform: translate(0%, -50%);
            transition-delay: 100ms;
          }

          &.idle {
            transition-delay: 0ms;
            opacity: 0;
            transform: translate(150%, -50%);
          }
        }
      }
    }
  }
}

.home-activities-item__fulllink {
  @include get-all-space;

  z-index: 999;
}

.home-activities-item__link {
  // prettier-ignore
  @include fluid(font-size, (xxs: 20px, xxl: 24px));

  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-weight: 400;

  &::after {
    transform-origin: left;
    transition: $transition-duration * 1.2 $ease-out;
    transition-property: transform;
    transform: scaleX(0);

    .is-visible & {
      transform: scaleX(1);
    }

    .application & {
      height: 3px;

      background: linear-gradient(
        90deg,
        $c-app-light 0%,
        $c-app-light 60%,
        $c-app-mid 60%,
        $c-app-mid 85%,
        $c-app-dark 85%,
        $c-app-dark 100%
      );
    }

    .processes & {
      height: 3px;
      background: linear-gradient(
        90deg,
        $c-processes-light 0%,
        $c-processes-light 60%,
        $c-processes-mid 60%,
        $c-processes-mid 85%,
        $c-processes-dark 85%,
        $c-processes-dark 100%
      );
    }

    .equipment & {
      height: 3px;
      background: linear-gradient(
        90deg,
        $c-equipment-light 0%,
        $c-equipment-light 60%,
        $c-equipment-mid 60%,
        $c-equipment-mid 85%,
        $c-equipment-dark 85%,
        $c-equipment-dark 100%
      );
    }
  }
}

.home-activities-item__picture {
  position: relative;
  overflow: hidden;
}
