
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































.library__body {
  @include fluid(
    padding-top,
    (
      xxs: 40px,
      xxl: 100px,
    )
  );

  @include fluid(
    padding-bottom,
    (
      xxs: 80px,
      xxl: 200px,
    )
  );

  @include mq(l) {
    display: flex;
    align-items: flex-start;
  }
}

.mobile-nav {
  margin-top: $spacing * 2;

  @include mq(l) {
    display: none;
  }
}

.library__body__navigation {
  @include mq($until: l) {
    display: none;
  }

  @include mq(l) {
    position: sticky;
    top: $header-height;
    width: col(3);
  }
}

.library__body__content {
  @include mq(l) {
    margin-left: col(1);
    width: col(12);
  }
}
