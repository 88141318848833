
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












.home-activities-grid {
  display: flex;
  flex-direction: column;
  gap: 5rem;

  @include mq(m) {
    flex-direction: row;
    gap: $spacing * 2.5;
  }
}

.home-activities-grid__item {
  width: 100%;

  @include mq(m) {
    width: calc(100% / 3);
  }
}
