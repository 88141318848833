
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































































































































































































.form-select {
  position: relative;
  display: flex;
  align-items: center;

  .form & {
    border-bottom: 1px solid $c-gray-medium;
    box-shadow: none;
  }
}

.form-multiple-select-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  font-weight: 300;
  -moz-appearance: textfield;
}

.form-multiple-select__label {
  color: $c-white;

  &.is-filled {
    color: $c-main-green;
  }
}

.form-multiple-select__trigger {
  @extend %button-nostyle;
  @extend %fw-bold;

  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $spacing * 0.9 0;
  // border: 1px solid $c-gray-light;
  border-bottom: 1px solid $c-gray-medium;
  transition: 0.3s $ease-80-20;

  .icon {
    transition: transform 0.2s;
  }

  &:hover,
  &:focus {
    .icon {
      transform: rotate(45deg);
    }
  }

  &.is-filled {
    padding: $spacing * 0.8 0;
  }

  .is-open & {
    border-bottom-color: rgba($c-gray-light, 0);

    .icon {
      transform: rotate(180deg);
    }
  }
}

.form-multiple-select__options {
  position: absolute;
  z-index: 2;
  // display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  text-align: left;
  background: $c-white;
  border: 1px solid $c-gray-light;
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s;

  .is-open & {
    box-shadow: 0 4px 20px rgba(177, 177, 177, 0.4);
    opacity: 1;
    pointer-events: all;
  }

  ul {
    @extend %list-nostyle;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: $spacing * 2 $spacing;
  }
}

.form-multiple-select__option {
  display: block;
  width: 48%;
  line-height: 3rem;
}

.form-multiple-select__options__button {
  margin: 0 2rem 3rem 0;
}
