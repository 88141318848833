
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































































































































.home-hero {
  position: relative;
  overflow: hidden;
  max-height: 100vh;
}

.home-hero-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.home-hero__content-inner {
  width: 100%;

  @include mq($until: s) {
    .home-hero__logo-outer + & {
      margin-top: $spacing;
    }
  }

  @include mq(s) {
    .home-hero__logo-outer + & {
      margin-left: 0;
    }
  }
}

.home-hero__picture-outer {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: $c-processes-dark;

  &::after {
    @include get-all-space;

    content: '';
    display: block;
    background: linear-gradient(
      0deg,
      rgba($c-black, 1) 20%,
      rgba($c-black, 0) 100%
    );
    opacity: 0.6;
  }
}

.home-hero__picture {
  @include image-fit;

  transform: scale(1.3);

  .home-hero.shaded & {
    opacity: 0.25;
  }
}

.home-hero__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: $spacing * 4;
  padding-top: 10vh !important; // stylelint-disable-line declaration-no-important
  padding-bottom: $spacing * 4 !important; // stylelint-disable-line declaration-no-important
  color: $c-main-green;

  @include mq(l) {
    padding-top: 15vh !important; // stylelint-disable-line declaration-no-important
  }

  @include mq(xl) {
    padding-top: 25vh !important; // stylelint-disable-line declaration-no-important
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: col(1);
    width: col(14);
    height: 2px;
  }
}

.home-hero__headline {
  @extend %fw-bold;
  @extend %text-uppercase;

  display: flex;
  align-items: flex-end;
  color: $c-gray-medium;
  font-family: $ff-alt;
  font-size: 1.6rem;
  line-height: 1.2em;
}

.home-hero__headline__category {
  margin-left: 0.4em;
}

.home-hero__title {
  max-width: 440px;

  .home-hero__picture + &,
  .home-hero__headline + & {
    margin-top: $spacing;
  }

  .home-hero.home &,
  .home-hero.shaded & {
    color: $c-white;
  }

  > div {
    overflow: hidden;
    line-height: 1.2;
  }

  @include mq(l) {
    max-width: 900px;
  }

  @include mq(xl) {
    max-width: 1020px;
  }

  @include mq(xxxl) {
    max-width: 1200px;
  }
}

.home-hero__cta .action--btn--link {
  color: $c-white;
}

.home-hero__keyfigures {
  margin-top: $spacing * 2;
  color: $c-white;

  @include mq($until: m) {
    max-width: 100% !important;
  }

  @include mq(m) {
    margin-top: $spacing * -1;
  }

  .keyfigure-wrapper {
    &::before {
      opacity: 1;
    }
  }
}
