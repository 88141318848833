
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























.library-navigation {
  display: flex;
  gap: $spacing;

  @include mq(l) {
    flex-direction: column;
  }
}

.library-navigation__link {
  position: relative;
  color: rgba($c-black, 0.3);
  font-size: 1.5rem;
  line-height: 2;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $c-main-green;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease-out;
  }

  &.router-link-active,
  &:hover {
    color: rgba($c-black, 1);

    &::after {
      transform-origin: left;
      transform: scaleX(1);
    }
  }
}
