
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































.contact-subject {
  // padding-top: $spacing * 3;
  padding-bottom: $spacing * 2;

  @include mq(m) {
    // padding-top: $spacing * 5;
    padding-bottom: $spacing * 3;
  }

  @include mq(l) {
    // padding-top: $spacing * 6;
    padding-bottom: $spacing * 4;
  }
}

.contact-subject-inner {
  @include mq(m) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.contact-subject__baseline {
  @include mq(m) {
    width: col(3);
  }
}

.contact-subject__items {
  display: flex;
  flex-direction: column;

  @include mq(m) {
    width: col(9);
  }
}

.contact-subject__item {
  width: 100%;
}
