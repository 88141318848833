
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































































































































































































































































































































































































































































.calculator__logo {
  width: 100%;
  max-width: 55.4rem;
}

.calculator__subtitle {
  color: $c-gray-dark;
}

.calculator__form {
  flex-shrink: 0;
  width: 100%;
  padding-inline: col(0.5, 14);
  padding-block: 10.6rem;
  background-color: $c-equipment-dark;

  @include mq(l) {
    width: col(7, 14);
  }

  @include mq(xl) {
    padding-inline: col(1, 14);
  }

  @include mq(wrapper) {
    width: col(6, 14);
  }
}

.calculator__form__inner {
  display: inline-block;
  width: 100%;
  // padding: 4rem col(1);

  @include mq(m) {
    // padding: 10.6rem 10.6rem 16rem;
  }
}

.calculator__form__fieldset {
  margin-top: 0;
  margin-inline: 0;
  padding: 0;
  border-width: 0;
}

.calculator__form__legend {
  padding: 0;
  color: $c-white;
}

.calculator__form__step {
  position: relative;
  display: flex;
  margin-bottom: 2.5rem;

  ::v-deep .form-select__select,
  ::v-deep .form-input {
    // Add extra level of specificity to make sure that we're actually overwritting
    // styles defined in component.
    padding: 2rem 2rem 1rem 0;
    color: $c-white;
    font-weight: 500;
    line-height: 1;
    border-bottom: 1px $c-white solid;

    &:hover,
    &:focus {
      border-color: $c-white;
    }
  }
}

.calculator__form__step-count {
  margin-right: 2rem;
  color: $c-app-dark;
  font-size: 2.4rem;
  font-variant-numeric: tabular-nums;
}

.calculator__sign {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  color: $c-white;
  font-weight: 500;
}

.calculator__form__select {
  flex-grow: 1;

  // We want the fields to be required but we do not want to show *
  ::v-deep .form-label__required {
    display: none;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
::v-deep .form-input {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield;

  /* stylelint-disable-next-line no-descending-specificity */
  &::-webkit-inner-spin-button {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }
}

::v-deep .form-label {
  // padding: 2rem 0 1rem;
  padding-right: 2rem;
  // line-height: 1;
  color: $c-white;

  &.is-filled {
    color: $c-app-mid;
    transform: translateY(-2rem);
  }
}

::v-deep .form-feedback {
  bottom: -0.3rem;
}

.calculator__submit {
  flex-direction: row-reverse;
  align-items: center;

  // Ugly selector to overweight the one defined in Action.vue
  &[class*='action--'][class*='--button']:not([class*='--transparent']) {
    color: $c-white;
    background-color: $c-app-mid;

    &:hover {
      background-color: $c-app-dark;
    }
  }

  ::v-deep {
    .action__label {
      margin-right: 0;
      margin-left: 1rem;
    }
  }

  &:hover {
    ::v-deep {
      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      .action__icon {
        transform: translateX(0);
      }
    }
  }

  &.loading {
    pointer-events: none;
    background-color: #80808052;
  }
}

.calculator__wrapper {
  display: flex;
  flex-direction: column;

  @include mq(l) {
    flex-direction: row;
  }
}

.calculator__result {
  position: relative;
  background-color: $c-main-beige;
  padding-inline: col(1, 14);
  padding-block: 8rem;

  @include mq($from: s) {
    padding-block: 8rem;
  }
}

.calculator__result__logo {
  position: absolute;
  top: 4rem;
  right: col(1, 14);
  width: 100%;
  max-width: 15rem;

  @include mq($from: s) {
    top: 8rem;
  }
}

.results__title {
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5rem;
  color: $c-app-dark;
  font-size: 1.8rem;
  line-height: 1.4;
}

.results__title__from {
  color: $c-black;
  font-size: 2.4rem;
}

.results__title__to {
  margin-top: 2.8rem;
  padding: 2rem 6.5rem;
  font-size: 2.4rem;
  font-weight: 700;
  border: 1px currentColor solid;

  @include mq(xs) {
    padding-inline: 2rem;
  }
}

.results__p {
  margin-bottom: 2rem;
  color: $c-gray-darker;
  font-size: 1.3rem;
  line-height: 1.5;
}

.results__ingredients {
  display: flex;
  flex-flow: column wrap;
  gap: 2rem;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: $c-gray-light 1px solid;

  @include mq(s) {
    flex-direction: row;
  }
}

.results__ingredient {
  display: flex;
  flex-direction: column;
  width: calc(50% - 1rem);
}

.results__ingredient__quantity {
  color: $c-app-dark;
}

.results__ingredient__name {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}

.calculator__error {
  margin-top: 5rem;
  padding: 1rem;
  color: $c-dev-error;
  line-height: 1.5rem;
  border: 1px currentColor solid;
}

[data-animate-in] {
  opacity: 0;
}
