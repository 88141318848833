
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































































































































.form-select-outer {
  position: relative;

  .form & {
    flex-basis: 100%;
    margin: 0 $spacing * 0.5 $spacing;
  }

  @include mq(m) {
    .form & {
      flex-basis: 100%;
      margin: 0 $spacing * 0.5 3.2rem;
    }
  }
}

.form-select {
  position: relative;
  display: flex;
  align-items: center;

  .form & {
    border-bottom: 1px solid $c-gray-medium;
    box-shadow: none;
  }
}

.form-select__icon {
  @include center-y;

  fill: $c-white;
  right: 0;
  pointer-events: none;

  .form &:first-child {
    display: none;
  }
}

.form-select__select {
  @extend %select-nostyle;

  width: 100%;
  padding: $spacing * 0.8 0 $spacing * 0.8;
  color: $c-white;
  cursor: pointer;
}

.form-select__option {
  padding: 2rem;
  color: $c-gray-darkest;
}

.form-label {
  color: $c-white;
  pointer-events: none;

  &.is-filled {
    color: $c-main-green;
  }
}
