
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































































































































































































































































.library-listing__head {
  display: flex;
}

.library-listing__wysiwyg {
  margin-top: $spacing;
}

.library-listing__head__button-outer {
  position: relative;
  margin-left: $spacing * 2;
}

.library-listing__head__filters {
  position: absolute;
  z-index: 999;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 25rem;
  transform: translate(0, 100%);
  padding: 2rem;
  background-color: $c-white;
  border: 1px solid $c-gray-light;
  opacity: 0;
  transition: opacity 0.2s ease-out;

  &.is-open {
    opacity: 1;
  }
}

.library-listing__head__filter {
  position: relative;
  cursor: pointer;
  padding-left: $spacing * 1.5;

  &::before {
    @include center-y;

    content: '';
    left: 0.3rem;
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: $c-main-green;
    border-radius: 0.3rem;
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  &::after {
    @include center-y;

    content: '';
    left: 0;
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    border: 1px solid $c-gray-medium;
    border-radius: 0.3rem;
  }

  &.is-checked {
    color: $c-main-green;

    &::before {
      opacity: 1;
    }
  }
}

.library-listing__items {
  margin-top: $spacing * 2;

  @include mq(m) {
    margin-top: $spacing * 3;
  }

  @include mq(xl) {
    margin-top: $spacing * 4;
  }
}

.search-tool {
  margin: $spacing * 4 0 $spacing;
  padding: $spacing * 2;
  border: 1px solid $c-gray-light;

  ::v-deep {
    .form-label {
      color: $c-gray-medium;

      &.is-filled {
        color: $c-main-green;
      }
    }

    .form-select__select {
      color: $c-black;
      font-weight: 600;
    }

    .form-select__icon {
      fill: $c-black;
    }
  }

  @include mq(m) {
    padding: $spacing * 3;
  }
}

.search-tool__input {
  ::v-deep {
    .form-input {
      font-size: 3rem;
      font-weight: 400;
    }

    .form-label.is-filled {
      transform: translateY(-4rem);
    }
  }
}

.search-tool__selects {
  margin: $spacing * 2 0;
  padding: 0;
  border: 0;

  > * + * {
    margin-top: $spacing;
  }

  @include mq(m) {
    display: flex;
    align-items: center;

    > * + * {
      margin-top: 0;
      margin-left: $spacing * 1.5;
    }
  }
}

.search-tool__select {
  flex-grow: 1;

  ::v-deep {
    .form-label__icon {
      width: 2rem;
      height: 2rem;
      border-radius: 0;
    }
  }
}

.search-tool__results {
  color: $c-gray-dark;
}

.search-tool__empty {
  margin-top: $spacing;
  color: $c-gray-medium;
  font-size: 2.4rem;
}

.sticky-disclaimer {
  @include center-x;

  position: fixed;
  z-index: 9;
  bottom: 20px;
  padding: $spacing $spacing * 1.5;
  font-size: 1.5rem;
  background-color: $c-main-beige;
}

.sticky-disclaimer__link {
  color: $c-main-green;
  text-decoration: underline;
}
