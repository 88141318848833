/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * naming: if no idea, check http://chir.ag/projects/name-that-color/
 * order: from dark to light
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-gray-darkest: #202020; // ENVSWITCHER
$c-gray-darker: #606060;
$c-gray-dark: #808080;
$c-gray-medium: #b5b5b5;
$c-gray: #e0e0e0;
$c-gray-light: #ededed;
$c-gray-lighter: #f6f6f6;
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;
$c-red: #f00;
$c-green: #17b603;
$c-lemon: #f8e71c;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-body: $c-black;
$c-timeline-green: #177054;

// Main
$c-main-green: #66c430;
$c-main-beige: #faf6f1;

// Application
$c-app-dark: #318700;
$c-app-mid: #66c430;
$c-app-light: #89e661;

// Processes
$c-processes-dark: #005c04;
$c-processes-mid: #1cb222;
$c-processes-light: #4ade50;

// Equipment
$c-equipment-dark: #003928;
$c-equipment-mid: #09946a;
$c-equipment-light: #00dc9a;

/*
* Dimensions
*/
$content-width-max: mq-get-breakpoint-width(wrapper);
$header-height-mobile: 80px;
$header-height: 100px;
$internal-nav-height-mobile: 43px;
$internal-nav-height: 47px;
$header-breakpoint: l;
$header-z-index: 1000;
$flexible-wysiwyg-max-width: 73.5rem;

$spacing: 2rem;

/*
* Typography
*/

$ff-default: 'Inter', sans-serif;
$ff-alt: 'Inter', sans-serif;

$fs-base: 10px !default;
$fs-body: 1.5rem;
$fs-body-m: 1.5rem;

/**
* RWD
*/
$mq-responsive: true;
$mq-static-breakpoint: 'large';

/*
* Motion
*/
$transition-duration: 250ms;
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19); // ease-in-cubic
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1); // ease-out-cubic
$ease-80-20: cubic-bezier(0, 0.91, 0, 1);
